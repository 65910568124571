<template>
  <!-- 直播间 -->
  <div class="live-container">
    <div class="main-container">
      <div class="leftArea">
        <div class="liveMainArea" id="liveMainArea" ref="liveMainArea">
          <img src="@/assets/live/liveRoom.png" alt="" />
          <div class="v-icon" @click="startLive"></div>
          <div class="text">
            {{
              this.liveDetail.status &&
              (this.liveDetail.status.value == 0 ||
                this.liveDetail.status.value == 2)
                ? "点击播放直播预告"
                : "点击前往直播间"
            }}
          </div>
        </div>
        <div class="infoArea">
          <div class="detail-title">直播简介</div>
          <div style="margin: 30px 0">
            北美求职，秋招春招，公司情况，刷题相关问题，均可在讨论区提问讨论
            <br />
            Edward老师在直播时会解答讨论区内相关问题，面经题请在面经板块发表，请大家踊跃发言～欢迎提问和讨论～
          </div>
        </div>
        <div class="discussArea">
          <div class="detail-title">讨论区</div>
          <el-row
            v-if="userInfo && userInfo.id"
            style="margin: 30px 0; margin-bottom: 0px"
          >
            <el-col :span="2" style="text-align: right">
              <img class="userInfo avatar" :src="userInfo.avatar" alt="" />
            </el-col>
            <el-col :span="21">
              <el-row>
                <el-input
                  type="textarea"
                  v-model="comments"
                  :rows="3"
                  placeholder="写下你的评论..."
                  ref="activityCom"
                  resize="none"
                >
                </el-input>
              </el-row>
              <el-row style="margin-top: 12px">
                <el-col :span="6">
                  <emotions @chooseEmotion="handleEmotion"></emotions>
                </el-col>
                <el-col :offset="14" :span="4">
                  <el-button
                    type="primary"
                    size="mini"
                    style="width: 100%"
                    @click="publishComment"
                    >发表评论</el-button
                  >
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <comments
            :custom-container-style="customStyle"
            :hidden-title="true"
            :type="4"
            :commentTotal="0"
          ></comments>
        </div>
      </div>
      <div class="rightArea">
        <div class="liveDetail">
          <div class="title">北美面经直播</div>
          <div class="subtitle">
            请大家把不会的面经题，或自己面试后的面经，发布到我们平台，每周我们会挑选30·50道题目进行直播，按公司分类，讲解题目答案思路和核心代码，当然，大家有关于北美求职相关问题，刷题方面疑惑我们也会在直播时进行解答
          </div>
          <div style="text-align: center">
            <el-button type="primary" style="width: 100%" @click="openChoose"
              >发布面经</el-button
            >
          </div>
        </div>

        <div class="liveDescription">
          <div class="title">直播信息</div>
          <div class="detail">
            <div>直播主讲：<span>Edward Shi</span></div>
            <div>直播时长：<span>60-90分钟</span></div>
            <div>直播时间：<span>每周直播，提前3天邮件通知</span></div>
            <div>直播面经数量：<span>30-50道</span></div>
          </div>
          <div class="tips">Tips：面经题目提供越多，开播越早</div>
          <el-button
            style="width: 100%"
            type="danger"
            v-if="!liveDetail.subscription"
            @click="openSubscribe"
            >邮件订阅提醒</el-button
          >
          <el-button
            style="width: 100%"
            type="info"
            v-else
            @click="openSubscribe"
            >已订阅</el-button
          >
        </div>
        <div class="liveQrcode">
          <div class="qrcode-container">
            <div class="title">北美面经直播微信群</div>
            <img :src="qrcodeUrl" alt="" class="qrcode" />
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="chooseVisible" width="500px">
      <div>
        <div class="aside-rt">
          <div class="operateButton" @click="$router.push('/interviewEdit')">
            <i class="el-icon-edit-outline"></i>发面经
            <div class="tip">面试经验，完整面试流程</div>
          </div>
        </div>
        <div class="aside-rt">
          <div class="operateButton" @click="askQuestions">
            <i class="el-icon-search"></i>问面经题
            <div class="tip">面经不会，提问题，我们做答案</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <createQuestion
      :visible="questionVisible"
      @changeCreateQuestionVisible="(val) => (questionVisible = val)"
    ></createQuestion>
    <liveVideo
      v-if="videoVisible"
      @closeVideo="videoVisible = false"
    ></liveVideo>
    <emailSubscribe
      :visible="emailSubscribeVisible"
      @changeVisible="(val) => (emailSubscribeVisible = val)"
      @subscribeEvent="getLiveDetail"
    ></emailSubscribe>
  </div>
</template>
<script>
import emotions from "@/utils/emotions/index";
import { mapState } from "vuex";
import { publishComment } from "@/service/comments";
import comments from "@/components/comments/index";
import { getCourseBanner } from "@/service/index";
import createQuestion from "../interview/createQuestion";
import { getLiveDetail } from "@/service/live";
import liveVideo from "./liveVideo";
import emailSubscribe from "./emailSubscribe";
export default {
  name: "liveRoom",
  components: {
    emotions,
    comments,
    createQuestion,
    liveVideo,
    emailSubscribe,
  },
  data() {
    return {
      qrcodeUrl: "",
      comments: "",
      chooseVisible: false,
      questionVisible: false,
      liveDetail: {},
      videoVisible: false,
      emailSubscribeVisible: false,
      customStyle: {
        margin: 0,
        padding: "0 12px",
        boxShadow: "none",
      },

      vodPlayerJs: "https://player.polyv.net/resp/vod-player-drm/canary/player.js",
      vId: "5c1d111f63618bac2bf44e31bf1faa8a_5",
      player: null,
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {
    getCourseBanner(8).then((res) => {
      if (res.success) {
        this.qrcodeUrl = res.result[0].imageUrl;
      }
    });
    this.getLiveDetail();
  },
  methods: {
    initVideo() {
      this.$nextTick(() => {
        this.loadPlayerScript(this.loadPlayer);
      });
    },
    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },
    loadPlayer() {
      if (this.player) {
        this.player.destroy();
      }
      const polyvPlayer = window.polyvPlayer;
      this.player = polyvPlayer({
        wrap: "#liveMainArea",
        width: 1100,
        height: 600,
        vid: this.vId,
        df: 3,
        autoplay: false,
      });
    },
    getLiveDetail() {
      getLiveDetail().then((res) => {
        if (res.success) {
          this.liveDetail = res.result;
          if (
            this.liveDetail.status &&
            (this.liveDetail.status.value == 0 ||
              this.liveDetail.status.value == 2)
          ) {
            this.$refs.liveMainArea.innerHTML = "";
            this.$nextTick(() => {
              this.initVideo();
            });
          }
        }
      });
    },
    publishComment() {
      if (this.comments == "") {
        return this.$message.error("请输入评论内容！");
      }
      const data = {
        content: this.comments,
        superType: 4,
        type: true,
        superId: 1,
        parentId: 0,
      };
      publishComment(data).then((res) => {
        if (res.success) {
          this.$message.success("评论成功！");
          this.$bus.$emit("updateCommentList", "update");
          this.comments = "";
        }
      });
    },
    handleEmotion(emotion) {
      this.comments = this.comments + emotion;
    },
    initVideo() {
      this.$nextTick(() => {
        this.loadPlayerScript(this.getInfos);
      });
    },
    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },
    loadPlayer() {
      if (this.player) {
        this.player.destroy();
        this.loadPlayerScript(() => {
          this.vId = "5c1d111f63fb478c3cfc86e2fea7bfe0_5";
        });
      }
      const polyvPlayer = window.polyvPlayer;
      this.player = polyvPlayer({
        wrap: "#liveMainArea",
        width: 1100,
        height: 600,
        vid: this.vId,
        df: 3,
        autoplay: false,
      });
    },
    getInfos() {
      setTimeout(() => {
        this.loadPlayer();
      }, 300);
    },
    askQuestions() {
      console.log(this.$router);
      if (!this.userInfo && !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      this.chooseVisible = false;
      this.questionVisible = true;
    },
    startLive() {
      if (
        this.liveDetail.status &&
        (this.liveDetail.status.value == 0 || this.liveDetail.status.value == 2)
      ) {
        // this.videoVisible = true;
        this.$refs.liveMainArea.innerHTML = "";
        this.$nextTick(() => {
          this.initVideo();
        });
      } else if (this.liveDetail.status && this.liveDetail.status.value == 1) {
        this.$router.push(`/livePage?roomNumber=${this.liveDetail.polyvId}`);
      }
    },
    openSubscribe() {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      this.emailSubscribeVisible = true;
    },
    openChoose() {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      this.chooseVisible = true;
    },
  },
};
</script>
<style scoped lang="scss">
.live-container {
  width: 100%;

  background: #f4f4f4;
}
.main-container {
  width: 1500px;
  margin: 0 auto;
}
.leftArea {
  width: 1100px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
}
.rightArea {
  width: calc(100% - 1110px);
  display: inline-block;
  vertical-align: top;
}
.liveMainArea {
  margin-bottom: 10px;
  position: relative;
  img {
    width: 100%;
    vertical-align: top;
  }
  .text {
    position: absolute;
    left: 50%;
    bottom: 30%;
    transform: translateX(-50%);
    font-size: 26px;
    line-height: 80px;
    text-align: center;
    color: #fff;
  }
}
.infoArea {
  margin-bottom: 10px;
}
.liveDetail,
.liveDescription,
.infoArea,
.discussArea {
  padding: 20px 30px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  vertical-align: top;
}
.liveQrcode {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 24px;
  text-align: center;
  .qrcode-container {
    .title {
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #34495e;
      line-height: 21px;
      margin-bottom: 18px;
    }
    .qrcode {
      width: 100px;
      height: 100px;
      vertical-align: top;
      border: 2px solid #0075f6;
      border-radius: 2px;
    }
  }
}
.liveDescription {
  padding: 24px 66px !important;
  margin-bottom: 10px;
  .detail {
    text-align: left;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(52, 73, 94, 0.7);
    line-height: 21px;
    span {
      color: #0075f6;
    }
  }
}
.detail-title {
  position: relative;
  padding-left: 16px;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #34495e;
  line-height: 24px;
  margin-bottom: 32px;
}
.liveDetail {
  padding: 24px 66px;
  .title {
    text-align: center;
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #34495e;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .subtitle {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: rgba(52, 73, 94, 0.7);
    line-height: 20px;
    margin-bottom: 30px;
  }
}

.detail-title::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 4px;
  height: 24px;
  background: #0084ff;
  border-radius: 2px;
}
.wait-to-explain,
.tips,
.joinIn {
  text-align: center;
}
.live-title {
  color: #34495e;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #34495e;
  line-height: 24px;
  margin-bottom: 28px;
}
.live-duration,
.live-time {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: rgba(52, 73, 94, 0.7);
  line-height: 21px;
  margin-bottom: 14px;
}
.wait-to-explain {
  margin-top: 14px;
  padding-top: 30px;
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #34495e;
  line-height: 21px;
  border-top: 1px dashed rgba(0, 0, 0, 0.1);
}
.tips {
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #898989;
  line-height: 18px;
  margin-top: 20px;
  margin-bottom: 26px;
}
#liveMainArea {
  min-height: 600px;
}
.job-group {
  width: 100%;
  background: #fff;
  border-radius: 4px;
  text-align: center;
  padding: 0;
  display: inline-block;
  padding: 3px 17px 7px;
}
.live-group {
  text-align: left;
  padding: 24px;
  margin-bottom: 8px;
}
.job-group img {
  width: 140px;
  height: 140px;
  margin: 0 auto;
}
.joinIn {
  margin-bottom: 32px;
}
.liveDescription {
  margin-top: 10px;
  padding: 32px 48px;
  text-align: center;
}
.liveDescription .title {
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #34495e;
  line-height: 24px;
  margin-bottom: 15px;
}
.liveDescription p {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: rgba(52, 73, 94, 0.7);
  line-height: 20px;
  text-align: left;
  margin-bottom: 44px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: transparent;
}
::v-deep .el-tabs__active-bar {
  background-color: #fa6400;
  border-radius: 2px;
  height: 4px;
  min-width: 28px;
}
::v-deep .el-tabs__item.is-active,
::v-deep .el-tabs__item:hover {
  color: #fa6400;
}

.userInfo.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}

.ms-cell-interview {
  background-color: #fff;
  border-radius: 2px;
  margin-bottom: 10px;
  padding: 25px 30px 30px 50px;
}
.ms-cell-interview:not(:last-child) {
  border-bottom: 1px dashed rgba(151, 151, 151, 0.2);
}
.ms-cell-data .el-button--text {
  font-size: 12px;
  color: #34495e;
  padding: 0;
}
.pagination-block {
  text-align: center;
  padding: 20px 0;
}

.pagination-block .el-pagination {
  display: inline-block;
  position: relative;
  padding: 0 40px;
}

.pagination-block .el-pagination ::v-deep .el-pager li {
  min-width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: normal;
}

.pagination-block .el-pagination ::v-deep .el-pager {
  padding: 0 30px;
}

.pagination-block .el-pagination ::v-deep .first {
  font-weight: normal;
  position: absolute;
  left: 0;
}

.pagination-block .el-pagination ::v-deep .last {
  font-weight: normal;
  position: absolute;
  right: 0;
}

.pagination-block .el-pagination ::v-deep button,
.pagination-block .el-pagination ::v-deep span {
  min-width: 36px;
  font-size: 16px;
  height: 36px;
  line-height: 36px;
  background: transparent;
}

.pagination-block .el-pagination ::v-deep button {
  margin: 0 25px;
}

.aside-rt {
  margin-bottom: 16px;
  background: #ffffff;
  border: 1px solid rgba(117, 117, 117, 0.5);
  border-radius: 4px;
  &:hover {
    border: 1px solid rgba(0, 132, 255, 1);
    .operateButton,
    .tip {
      color: rgba(0, 132, 255, 1);
    }
  }
  .operateButton {
    padding: 24px;
    text-align: center;
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #34495e;
    line-height: 16px;
    cursor: pointer;
    i {
      margin-right: 6px;
    }
  }
  .tip {
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #999999;
    line-height: 18px;
    margin-top: 12px;
  }
}
.v-icon {
  background: url(../../assets/live/play.png) center no-repeat;
  width: 60px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: change 0.71s ease-in-out infinite alternate;
  z-index: 2;
  cursor: pointer;
  border-radius: 50%;
}
@keyframes change {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>